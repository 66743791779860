import type { LineItemType, Product } from 'productSelection/types/products';
import { addLineItem } from 'shared/hooks/orders/AddProductService';
import { BUSINESS_ACCOUNT_MODAL_IS_COMPLETED } from 'shared/infra/cookies';
import logger from 'shared/services/logger';
import type { Product as TrackProduct } from 'shared/services/tracker/types';
import type { CatalogState } from 'shared/store/catalog/types';
import type { OrderLineItem } from 'shared/store/order/types';
import type { SKUCode } from 'shared/types/ids';
import type { Maybe } from 'types/util';

export const BA_FIXED_QUANTITY = 1;

export type BusinessAccountProduct = {
  product: Product;
  localizedName: string;
  code: SKUCode;
  lineItemType: LineItemType;
};

export async function addBusinessAccount(
  orderId: string,
  businessAccountProduct: BusinessAccountProduct,
  catalog: CatalogState,
): Promise<void> {
  try {
    const { formattedAmount, trackingId } =
      catalog[businessAccountProduct.code];
    const addLineItemReq = {
      orderId,
      code: businessAccountProduct.code,
      quantity: BA_FIXED_QUANTITY,
      localizedName: businessAccountProduct.localizedName,
      trackingId,
      lineItemType: businessAccountProduct.lineItemType,
      price: formattedAmount,
    };

    await addLineItem(addLineItemReq);
  } catch (err) {
    logger.error(err);
  }
}

export function removeBusinessAccountProductsFromCart(
  lineItems: OrderLineItem[],
  products: Product[],
): OrderLineItem[] {
  return lineItems.filter((lineItem) => {
    const isBAProduct = products.find(
      (product) => lineItem.code === product.businessAccount?.code,
    );

    return !isBAProduct;
  });
}
export function isBusinessAccountInCart(
  lineItems: OrderLineItem[],
  products: Product[],
): boolean {
  const businessAccountProduct = lineItems.find((lineItem) =>
    products.find((product) => lineItem.code === product.businessAccount?.code),
  );

  return !!businessAccountProduct;
}

export function makeBusinessAccountProduct(
  products: Product[],
  lineItems: OrderLineItem[],
): Maybe<BusinessAccountProduct> {
  const productInOrder = getProductForOrder(products, lineItems);

  const productWithBusinessAccount = productInOrder.find(
    (product) => product.businessAccount,
  );

  if (!productWithBusinessAccount) {
    return null;
  }

  return {
    product: productWithBusinessAccount,
    localizedName: productWithBusinessAccount.businessAccount.name,
    code: productWithBusinessAccount.businessAccount.code,
    lineItemType: 'sku',
  };
}

export function makeBusinessAccountProductForTracking(
  baProduct: BusinessAccountProduct,
  catalog: CatalogState,
): TrackProduct {
  return {
    code: baProduct.code,
    trackingId: catalog[baProduct.code]?.trackingId,
    price: catalog[baProduct.code]?.formattedAmount,
    quantity: BA_FIXED_QUANTITY,
  };
}

export function isBAModalCompleted(): boolean {
  return localStorage.getItem(BUSINESS_ACCOUNT_MODAL_IS_COMPLETED) === 'true';
}

export const ensureBAModalIsCompleted = (): void => {
  localStorage.setItem(BUSINESS_ACCOUNT_MODAL_IS_COMPLETED, 'true');
};

function getProductForOrder(
  products: Product[],
  productsInCart: OrderLineItem[],
): Product[] {
  return products.filter(
    (product: Product) =>
      !!productsInCart.find(
        (cartProduct: OrderLineItem) => cartProduct.code === product.code,
      ),
  );
}
