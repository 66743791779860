import type { LineItemType } from 'productSelection/types/products';
import * as OrderAPI from 'shared/infra/commerceLayer/orders';
import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';
import {
  dispatchAddProductEvent,
  dispatchAddProductFailureEvent,
  dispatchRemoveProductEvent,
  dispatchRemoveProductFailureEvent,
} from 'shared/services/tracker/events';
import type { ManageProductCustomData } from 'shared/services/tracker/types';
import type { CatalogState } from 'shared/store/catalog/types';
import type { OrderLineItem } from 'shared/store/order/types';
import { createSKUCode, type SKUCode } from 'shared/types/ids';
import type { Maybe } from 'types/util';

type BusinessAccountItemReq = {
  enabled: boolean;
  selected: boolean;
  code: SKUCode;
  localizedName: string;
  lineItemType: LineItemType;
};

export type AddProductRequest = {
  id: Maybe<string>;
  code: SKUCode;
  trackingId: string;
  formattedUnitAmount: string;
  quantity: number;
  couponCode?: string;
  allowOnlyOne: boolean;
  locale: string;
  lineItemType: LineItemType;
  localizedName?: string;
  businessAccount?: BusinessAccountItemReq;
};

type TrackLineItemReq = {
  trackingId: string;
  quantity: number;
  price: string;
  code: SKUCode;
};

export type AddLineItemReq = TrackLineItemReq & {
  orderId: string;
  code: SKUCode;
  lineItemType: LineItemType;
  localizedName?: string;
};

type RemoveLineItemReq = TrackLineItemReq & {
  id: string;
};

const buildTrackEvent = (
  lineItem: TrackLineItemReq,
): ManageProductCustomData => ({
  product: {
    trackingId: lineItem.trackingId,
    code: lineItem.code,
    quantity: lineItem.quantity,
    price: lineItem.price,
  },
  currency: '',
});

const removeLineItem = (req: RemoveLineItemReq): Promise<void> => {
  const trackEvent = buildTrackEvent(req);

  try {
    void dispatchRemoveProductEvent(trackEvent);

    return OrderAPI.removeProduct(req.id);
  } catch (err) {
    void dispatchRemoveProductFailureEvent(trackEvent);

    throw err;
  }
};

export const addLineItem = async (req: AddLineItemReq): Promise<void> => {
  const trackEvent = buildTrackEvent(req);

  try {
    const lineItem = await OrderAPI.addProduct(
      req.orderId,
      req.code,
      req.lineItemType,
      req.quantity,
      req.localizedName,
    );

    trackEvent.currency = lineItem.currency_code ?? undefined;
    void dispatchAddProductEvent(trackEvent);
  } catch (err) {
    void dispatchAddProductFailureEvent(trackEvent);

    throw err;
  }
};

const ensureBusinessAccountLineItemCreated = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  const {
    enabled = false,
    selected = false,
    code = createSKUCode(''),
    localizedName = '',
    lineItemType = 'sku',
  } = req.businessAccount || {};

  if (!enabled) {
    return undefined;
  }

  const businessAccountProduct = orderLineItems.find(
    ({ code: skuCode }) => skuCode === code,
  );

  if (selected && !businessAccountProduct) {
    const addLineItemReq = {
      orderId,
      code,
      quantity: 1,
      localizedName,
      formattedTotalAmount: catalog[code].formattedAmount,
      trackingId: catalog[code]?.trackingId,
      lineItemType,
      price: catalog[code]?.formattedAmount,
    };

    return addLineItem(addLineItemReq);
  }

  if (!selected && businessAccountProduct) {
    const removeLineItemReq: RemoveLineItemReq = {
      id: businessAccountProduct.id,
      quantity: 1,
      trackingId: catalog[code]?.trackingId,
      price: catalog[code]?.formattedAmount,
      code: req.code,
    };

    return removeLineItem(removeLineItemReq);
  }

  return undefined;
};

export const cleanupRegularLineItems = async (
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  if (!req.allowOnlyOne) {
    return;
  }

  const regularLineItems = orderLineItems.filter(
    (product) => product.code !== req?.businessAccount?.code,
  );

  await Promise.all(
    regularLineItems.map((lineItem) => {
      const removeLineItemReq: RemoveLineItemReq = {
        id: lineItem.id,
        quantity: lineItem.quantity,
        trackingId: catalog[lineItem.code]?.trackingId ?? '',
        price: catalog[lineItem.code]?.formattedAmount ?? '',
        code: lineItem.code,
      };

      return removeLineItem(removeLineItemReq);
    }),
  );
};

const ensureRegularLineItemsCreated = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  await cleanupRegularLineItems(req, orderLineItems, catalog);
  await addLineItem({
    orderId,
    code: req.code,
    quantity: req.quantity,
    localizedName: req.localizedName,
    price: catalog[req.code]?.formattedAmount,
    trackingId: catalog[req.code]?.trackingId,
    lineItemType: req.lineItemType,
  });
};

const ensureCouponCodeAttached = async (
  orderId: string,
  couponCode: Maybe<string>,
): Promise<void> => {
  if (!couponCode && couponCode !== '') {
    return;
  }

  await OrderAPI.updateOrder(orderId, {
    coupon_code: processCouponCodeToOrder(couponCode),
    _update_taxes: true,
  });
};

export const addProduct = async (
  orderId: string,
  req: AddProductRequest,
  orderLineItems: OrderLineItem[],
  catalog: CatalogState,
): Promise<void> => {
  await ensureRegularLineItemsCreated(orderId, req, orderLineItems, catalog);
  await ensureBusinessAccountLineItemCreated(
    orderId,
    req,
    orderLineItems,
    catalog,
  );
  await ensureCouponCodeAttached(orderId, req.couponCode ?? null);
};
