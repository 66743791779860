import type { Order } from '@commercelayer/sdk';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useLineItems } from 'checkout/hooks/useLineItems';
import { useChannel } from 'shared/context';
import { getLanguageFromISOLocale } from 'shared/i18n/helpers';
import { processCouponCodeToOrder } from 'shared/infra/commerceLayer/utils';
import {
  shouldUnplaceOrder,
  unplaceOrder,
} from 'shared/infra/storefront/orders';
import { useTypedSelector } from 'shared/store';
import {
  addProduct as addProductAction,
  addProductFailure,
  addProductSuccess,
} from 'shared/store/order/actions';

import * as AddProductService from './AddProductService';
import { useCreateOrder } from './useCreateOrder';
import { useGetOrder } from './useGetOrder';

type UseAddProductResult = {
  addProduct: (req: AddProductService.AddProductRequest) => Promise<Order>;
};

export const useAddProduct = (): UseAddProductResult => {
  const { getOrCreateLocalOrder } = useCreateOrder();
  const getOrder = useGetOrder();
  const dispatch = useDispatch();
  const channel = useChannel();

  const products = useTypedSelector((s) => s.order.products);
  const catalog = useTypedSelector((s) => s.catalog);
  const { changeLineItemQuantity } = useLineItems();

  const addProduct = useCallback(
    async (req: AddProductService.AddProductRequest) => {
      dispatch(addProductAction(req.code));

      try {
        const languageCode = getLanguageFromISOLocale(req.locale);
        // TODO: Create a new order during initialization
        // to speed up the process of adding a product.
        // it's also not the ideal place to have it here (the function does more than expected)
        const ensuredOrder = await getOrCreateLocalOrder({
          channel,
          couponCode: req.couponCode
            ? processCouponCodeToOrder(req.couponCode)
            : undefined,
          languageCode,
        });

        if (!ensuredOrder) {
          throw new Error('Order creation failed.');
        }

        if (req.id && !req.allowOnlyOne) {
          return await changeLineItemQuantity(
            {
              id: req.id,
              code: req.code,
              trackingId: req.trackingId,
              formattedUnitAmount: req.formattedUnitAmount,
            },
            req.quantity,
          );
        }

        await AddProductService.addProduct(
          ensuredOrder.id,
          req,
          products,
          catalog,
        );

        dispatch(addProductSuccess('Product added to the cart.'));

        const order = await getOrder(ensuredOrder.id);

        if (
          shouldUnplaceOrder(
            order.status,
            order?.payment_method?.reference ?? null,
          )
        ) {
          await unplaceOrder(order.id);
        }

        return order;
      } catch (err) {
        dispatch(
          addProductFailure('Failure while adding a product to the order.'),
        );

        throw err;
      }
    },
    [
      dispatch,
      getOrCreateLocalOrder,
      channel,
      products,
      catalog,
      getOrder,
      changeLineItemQuantity,
    ],
  );

  return { addProduct };
};
