import { css, type SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge, type BadgeProps } from '@sumup/circuit-ui';
import type { Theme } from '@sumup/design-tokens';

import type { Maybe } from 'types/util';

type Props = BadgeProps & { theme: Theme } & {
  isFree?: boolean;
};

const defaultStyles = (variant: string): Maybe<SerializedStyles> =>
  variant
    ? null
    : css`
        background-color: var(--cui-bg-accent);
        color: var(--cui-fg-accent);
      `;

export const DiscountBadge = styled(Badge)(
  ({ theme, variant, isFree }: Props) => css`
    color: var(--cui-fg-on-strong);
    margin-bottom: var(--cui-spacings-byte);
    user-select: none;

    ${defaultStyles(variant)};

    ${theme.mq.mega} {
      user-select: auto;
    }

    ${isFree &&
    css`
      margin-bottom: var(--cui-spacings-giga);
    `}
  `,
);
