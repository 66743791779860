import type { Order } from '@commercelayer/sdk';
import { useCallback } from 'react';

import type { Channel } from 'shared/constants/Channel';
import * as OrderAPI from 'shared/infra/commerceLayer/orders';
import {
  shouldResetOrder,
  shouldUnplaceOrder,
  unplaceOrder,
} from 'shared/infra/storefront/orders';
import {
  getAttributionParams,
  getTrackingParams,
} from 'shared/services/attribution';
import { saveLocalOrderInformation } from 'shared/services/OrderInformationService';
import {
  dispatchOrderCreateEvent,
  dispatchOrderCreateFailureEvent,
} from 'shared/services/tracker/events';
import { useTypedSelector } from 'shared/store';
import type { OrderDetails } from 'shared/store/order/types';

import { useGetOrder } from './useGetOrder';
import { useResetOrderState } from './useResetOrderState';

export interface CreateOrderReq {
  channel: Channel;
  couponCode?: string;
  languageCode?: string;
}

type UseCreateOrderResult = {
  getOrCreateLocalOrder: (
    req: CreateOrderReq,
  ) => Promise<Order | OrderDetails | null>;
};

export const useCreateOrder = (): UseCreateOrderResult => {
  const getOrder = useGetOrder();
  const resetOrder = useResetOrderState();

  const orderDetails = useTypedSelector((state) => state.order.orderDetails);

  const attrParams = getAttributionParams();
  const trackParams = getTrackingParams();

  const createOrder = useCallback(
    async (req: CreateOrderReq) => {
      try {
        const newOrder = await OrderAPI.createOrder(
          req.channel,
          req.couponCode,
          req.languageCode,
          attrParams,
          trackParams,
        );

        const updatedOrder = await getOrder(newOrder.id);

        if (
          shouldResetOrder(
            updatedOrder.status,
            updatedOrder?.payment_method?.reference ?? null,
          )
        ) {
          resetOrder();

          return null;
        }

        if (
          shouldUnplaceOrder(
            updatedOrder.status,
            updatedOrder?.payment_method?.reference ?? null,
          )
        ) {
          await unplaceOrder(updatedOrder.id);
        }

        saveLocalOrderInformation(updatedOrder.id);
        void dispatchOrderCreateEvent({
          is_referral: !!attrParams.referral_code,
        });

        return updatedOrder;
      } catch (err) {
        void dispatchOrderCreateFailureEvent({
          is_referral: !!attrParams.referral_code,
        });

        throw err;
      }
    },
    [attrParams, getOrder, resetOrder, trackParams],
  );

  const getOrCreateLocalOrder = useCallback(
    async (req: CreateOrderReq) => {
      if (orderDetails.id) {
        return orderDetails;
      }

      return createOrder(req);
    },
    [orderDetails, createOrder],
  );

  return { getOrCreateLocalOrder };
};
